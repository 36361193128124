:root {
  /* -- Fonts --*/
  --font-family-gotham: "Gotham", sans-serif; // var(--font-family-gotham);
  --font-family-manrope: "Manrope", sans-serif; // var(--font-family-manrope);

  /* -- Colors --*/
  --color-white: #fff; // var(--color-white);
  --color-black: #000; // var(--color-black);

  --color-grey: #F2F2F2; // var(--color-grey);
  --color-pink: #FA3277; // var(--color-pink);
  --color-red: #FF3B30; // var(--color-red);
  --color-gray-mid: #666666; // var(--color-gray-mid);
  --color-gray: #999999; // var(--color-gray);
  --color-blue-light: #64DBFA; // var(--color-blue-light);
  --color-yellow: #F9EA4A; // var(--color-yellow);

}










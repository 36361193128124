.rules {
  h2 {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }

  ul,
  ol {
    margin-bottom: 25px;
    margin-left: 20px;

    li {
      font-size: 14px;
      line-height: 20px;
      color: var(--color-gray-mid);
    }

    li + li {
      margin-top: 10px;
    }
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: auto;
  }

  p {
    margin-bottom: 25px;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-gray-mid);
  }

  p + p {
    margin-top: 10px;
  }
}

.top-files {
  display: flex;
  flex-direction: column;

  &__tabs {
    grid-gap: 35px;
  }

  &__head {
    width: fit-content;
    display: flex;
    flex-direction: column;
    grid-gap: 25px;

    .tabs__links {
      &:first-child {
        padding-left: 0;
      }

      @media (max-width: 576px) {
        padding: 0 20px 10px;

        &:last-child {
          padding-right: 0;
        }
      }
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &__sorting {
    display: flex;
    grid-gap: 10px;
  }

  &__sorting-btn {
    padding: 10px 16px;
    background-color: var(--color-black);
    border-radius: 50px;
    font-family: var(--font-family-gotham);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: var(--color-white);
    transition: all 0.3s ease;
  }


  &__18plus {
    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      visibility: hidden;

      &:checked ~ span::before {
        border: 2px solid var(--color-pink);
      }

      &:checked ~ span::after {
        opacity: 1;
        visibility: visible;
      }
    }

    span {
      position: relative;
      padding-left: 30px;
      font-size: 14px;
      line-height: 17px;
      color: var(--color-gray-mid);
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 20px;
        height: 20px;
        border: 2px solid #CCC;
        transition: all 0.4s ease;
      }

      &::after {
        content: '';
        position: absolute;
        background-image: var(--checked-icon);
        background-size: cover;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease;
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__items {
    max-width: 666px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}

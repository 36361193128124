.footer {
  padding: 43px 0;

  &__wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;

    span {
      color: #CCC;
    }
  }

  &__email {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: var(--color-pink);
  }

  &__copyright {
    font-family: var(--font-family-manrope);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: var(--color-gray);
  }
}

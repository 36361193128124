.header {
  padding: clamp(13px, 1.6vw, 20px) 0 clamp(17px, 2vw, 25px);

  &--line {
    @media (max-width: 576px) {
      margin-bottom: 14px;
      border-bottom: 2px solid var(--color-black);
    }
  }

  &__wrapp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: clamp(20px, 3.2vw, 40px);
  }

  &__mid {
    flex-grow: 1;
    display: flex;
    align-items: center;
    grid-gap: 20px;
  }

  &__add {
    width: fit-content;
    flex-shrink: 0;

    @media (max-width: 992px) {
      display: none;
    }
  }

  &__logo {
    svg {
      width: clamp(224px, 20vw, 250px);
      height: clamp(35px, 3.2vw, 40px);
    }
  }

  &__search {
    @media (max-width: 992px) {
      display: none;
    }
  }
}


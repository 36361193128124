.user {
  display: flex;
  align-items: center;
  grid-gap: 10px;

  &__avatar {
    position: relative;
    flex-shrink: 0;
    display: flex;
    width: 42px;
    height: 42px;
    border-radius: 50%;

    picture,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__login-medthod {
    position: absolute;
    display: flex;
    right: 1.3px;
    top: 2.2px;
    background-color: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 20px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 6px;
  }

  &__email {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__logout {
    color: var(--color-pink);
  }
}

.categories {
  margin-bottom: 45px;

  &__content {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  &__link {
    display: flex;
    align-items: center;
    grid-gap: 11px;
  }

  &__link-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--color-black);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      color: var(--color-gray);
    }
  }
}




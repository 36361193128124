.block {
  padding-bottom: 43px;

  &__title {
    span {
      color: var(--color-pink);
    }
  }

  &__search {
    display: none;

    @media (max-width: 992px) {
      margin-bottom: 20px;
      display: flex;

      button {
        text-transform: capitalize;
      }
    }

    @media (max-width: 576px) {
      span {
        display: none;
      }
    }
  }

  &__add-file {
    display: none;

    @media (max-width: 992px) {
      margin-bottom: 28px;
      display: flex;
      text-transform: capitalize;
    }
  }

  &__wrapp {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-gap: clamp(40px, 4vw, 50px);

    @media (max-width: 992px) {
      grid-template-columns: 100%;
    }
  }

  &__aside {
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 36px;

    @media (max-width: 992px) {
      max-width: 272px;
      width: 100%;
      padding: 18px 16px;
      background-color: var(--color-white);
      overflow: auto;
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s ease-in-out;
      transition-duration: 0.3s;
    }
  }

  &__top-links {
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  &__top-link {
    width: 100%;
    padding: 14px;
    border-radius: 12px;
    background-color: var(--color-yellow);
    display: flex;
    align-items: center;
    grid-gap: 16px;

    svg {
      flex-shrink: 0;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 8px;
    }
  }

  &__link-users {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    li {
      display: flex;
      border-radius: 50%;
      border: 4px solid var(--color-yellow);

      picture,
      img {
        border-radius: 50%;
      }
    }

    li + li {
      margin-left: -20px;
    }
  }

  &__link-title {
    flex-grow: 1;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__content-header {
    margin-bottom: clamp(20px, 2.4vw, 30px);
    display: flex;
    flex-direction: column;
    grid-gap: clamp(12px, 1.28vw, 16px);

    p {
      max-width: 592px;
      font-size: 16px;
      color: var(--color-gray-mid);
    }
  }


}

.sorting {
  display: flex;
  flex-direction: column;

  &__head {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-gray);
  }

  &__inner {
    margin-bottom: 30px;
    max-width: 330px;
    display: flex;
    align-items: center;
    grid-gap: 8px;

    @media (max-width: 576px) {
      max-width: 100%;
    }
  }

  &__labels {
    display: flex;
    align-items: center;
    grid-gap: 6px;

    label:first-child,
    label:last-child {
      max-width: 80px;
    }
  }

  &__input {
    padding: 10px 18px;
    border: 1px solid #CCC;
    border-radius: 50px;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-black);
    transition: all 0.3s ease-in-out;

    &::placeholder {
      color: var(--color-gray);
    }

    &:focus {
      border: 1px solid var(--color-black);
    }
  }

  &__btn {
    width: fit-content;
    padding: 11px 12px 13px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__counts {
    color: var(--color-gray-mid);
    font-weight: 700;

    span {
      color: var(--color-black);
    }
  }

  &__directions {
    display: none;

    @media (max-width: 576px) {
      display: flex;
      align-items: center;
      grid-gap: 16px;
    }
  }

  &__directions-trigger {
    display: flex;
    cursor: pointer;

    svg {
      stroke: var(--color-gray);
      transition: all 0.4s ease;
    }

    &.active {
      svg {
        stroke: var(--color-pink);
      }
    }
  }
}

.file {
  display: flex;
  align-items: flex-start;
  grid-gap: 12px;

  &__image {
    flex-shrink: 0;
    width: 73px;
    height: 73px;

    picture,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  &__title {
    color: var(--color-pink);
    font-weight: 700;
  }

  &__descr {
    font-size: 14px;
    line-height: 18px;
  }

  &__downloads {
    font-size: 14px;
    line-height: 18px;
    color: var(--color-gray-mid);
  }

  &__uploadtime {
    font-size: 16px;
    line-height: 20px;
    color: var(--color-black);
  }

  &__details {
    width: 100%;
    margin-bottom: clamp(15px, 2.734vw, 28px);

    li {
      padding: 8px 0;
      border-bottom: 1px solid var(--color-grey);

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    .file__downloads {
      font-size: 16px;
      line-height: 20px;
      color: var(--color-black);
    }
  }

  &__details-head {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-gray);
  }

  &__folders {
    display: flex;
    align-items: center;
    grid-gap: 8px;
  }

  &__folders-list {
    display: flex;
    align-items: center;
    grid-gap: 17px;

    li {
      position: relative;
      padding: 0;
      border: 0;

      &::before {
        content: "/";
        position: absolute;
        width: 2px;
        height: 100%;
        left: -12px;
        color: #CCC;
      }

      &:first-child::before {
        display: none;
      }
    }
  }

  &__author {
    display: flex;
    align-items: center;
    grid-gap: 11px;
  }

  &__author-link {
    color: var(--color-pink);
  }

  &__download-types {
    width: 100%;
    margin-bottom: clamp(25px, 3.32vw, 34px);

    li {
      padding: 8px 0;
      border-bottom: 1px solid var(--color-grey);

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &__download-link {
    display: inline-flex;
    align-items: center;
    grid-gap: 3px;
    color: var(--color-pink);

    @media (max-width: 576px) {
      display: flex;
    }
  }

  &__manage {
    margin-bottom: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 20px;
  }

  &__manage-left {
    display: flex;
    align-items: center;
    grid-gap: 17px;
  }

  &__manage-right {
    display: flex;
    align-items: center;
    grid-gap: clamp(15px, 3.906vw, 30px);
  }

  &__remove {
    padding: 10px 16px;
    border-radius: 50px;
    background-color: var(--color-grey);
    display: flex;
    align-items: center;
    grid-gap: 8px;
    font-size: clamp(14px, 2.083vw, 16px);
    line-height: clamp(18px, 2.604vw, 20px);
    color: var(--color-black);

    svg {
      stroke: currentColor;
      transition: all 0.4s ease;
    }
  }

  &__edit,
  &__favorite {
    padding: 12px;
    border-radius: 50px;
    background-color: var(--color-grey);
    display: flex;
    color: var(--color-black);

    svg {
      stroke: currentColor;
      transition: all 0.4s ease;
    }
  }

  &__ban {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    color: var(--color-black);
    flex-shrink: 0;

    svg {
      stroke: currentColor;
      transition: all 0.4s ease;
    }
  }

  &__delete {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    color: var(--color-red);
    flex-shrink: 0;

    svg {
      stroke: currentColor;
      transition: all 0.4s ease;
    }
  }
}

.burger {
  position: relative;
  z-index: 30;
  display: none;
  width: 24px;
  height: 24px;
  cursor: pointer;

  span {
    width: 100%;
    border: 2px solid var(--color-black);
    transition: all 0.3s ease;
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    grid-gap: 5px;
  }

  &.active {
    span {
      border: 2px solid var(--color-white);

      &:nth-child(1) {
        transform: rotate(45deg) translate(4px, 4px);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -7px);
      }
    }
  }
}

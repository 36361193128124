.comments {
  margin-bottom: clamp(36px, 4.492vw, 46px);
  width: 100%;

  &__heading {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;

    span {
      color: var(--color-gray);
    }
  }

  &__form {
    margin-bottom: 24px;

    label {
      display: flex;
      flex-direction: column;
      grid-gap: 10px;
    }

    textarea {
      height: 90px;
      padding: 10px 15px;
      border-radius: 12px;
      border: 1px solid #CCC;
      resize: none;
      transition: all 0.4s ease-in-out;

      &::placeholder {
        color: var(--color-gray);
      }

      &:focus {
        border: 1px solid var(--color-black);
      }
    }

    button {
      width: fit-content;
      padding: 10px 24px;
      text-transform: none;
    }
  }

  &__lists {
    display: flex;
    flex-direction: column;

    li {
      padding: 12px 0;
      border-bottom: 1px solid var(--color-grey);

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 1px solid transparent;
      }
    }
  }
}

.photos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: clamp(12px, 1.84vw, 23px);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__image {
    display: flex;

    picture,
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

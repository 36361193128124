.search {
  width: 100%;

  &__label {
    display: flex;
    align-items: stretch;
  }

  &__input {
    padding: 9px 17px;
    border-radius: 50px 0px 0px 50px;
    background-color: var(--color-grey);
    border: 1px solid transparent;
    font-family: var(--font-family-gotham);
    font-size: 14px;
    line-height: 22px;
    color: var(--color-black);
    transition: all 0.3s ease;

    &:focus {
      border: 1px solid var(--color-black);
      background-color: transparent;
    }
  }

  &__btn {
    padding: 11px 16px 11px 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 6px;
    background-color: var(--color-black);
    border-radius: 0px 50px 50px 0px;
    font-family: var(--font-family-gotham);
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: var(--color-white);
    text-transform: uppercase;
    transition: all 0.3s ease;
  }
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: var(--font-family-gotham);
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: var(--color-black);
}

html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a {
  display: inline-flex;
  color: currentColor;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  text-decoration: none;
  margin: 0;
  width: fit-content;
  cursor: pointer;
}

button {
  background-color: transparent;
  border: none;
  display: inline-flex;
  outline: none;
  padding: 0;
  margin: 0;
  color: currentColor;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  width: fit-content;
  cursor: pointer;
}

p {
  display: inline-block;
  padding: 0;
  margin: 0;
  line-height: initial;
}

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

picture {
  display: inline-flex;
}

// svg {
//   fill: currentColor;
// }

fieldset {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
}

input {
  width: 100%;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

textarea {
  width: 100%;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

label {
  display: block;
  position: relative;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.body-scroll {
  overflow-y: hidden;
}

.wrapper {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.main {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  position: relative;
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding: 0 clamp(16px, 3.419vw, 40px);
}

.disable-scroll {
  position: relative;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}



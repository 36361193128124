.videos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: clamp(20px, 2.08vw, 26px) 16px;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 100%;
  }

  .video {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;

    @media (max-width: 576px) {
      flex-direction: row;
      align-items: flex-start;
    }

    &__title {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.column {
    .video {
      flex-direction: column;
    }

    .video__bottom {
      flex-direction: row;
      grid-gap: 18px;
    }
  }
}

.mobile {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 20;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  // transition-delay: 0.2s;

  &.active {
    display: flex;
    width: 100%;
    height: 100%;

    .block__aside {
      opacity: 1;
      visibility: visible;
    }
  }
}

.pagination {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 25px;

  @media (max-width: 576px) {
    width: 100%;
    justify-content: space-between;
  }

  &__list {
    display: flex;
    align-items: center;
    grid-gap: clamp(5px, 0.868vw, 10px);
  }

  &__list-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid transparent;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover {
      border: 1px solid var(--color-pink);
    }

    &.active,
    &:active {
      font-size: 14px;
      line-height: 18px;
      border: 1px solid var(--color-pink);
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transition: all 0.3s ease;
  }
}

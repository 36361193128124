.video {
  &__preview {
    position: relative;
    display: flex;

    picture,
    img {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: clamp(17px, 1.953vw, 20px);

    span {
      color: var(--color-gray);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
  }

  &__duration,
  &__resolution {
    position: absolute;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    line-height: 16px;
    color: var(--color-white);
  }

  &__resolution {
    padding: 4px 8px;
    left: 10px;
    top: 10px;
  }

  &__duration {
    padding: 4px 6px;
    right: 10px;
    bottom: 10px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    grid-gap: 18px;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-gray-mid);

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 4px;
    }
  }

  &__file {
    margin-bottom: clamp(17px, 2.539vw, 26px);
    display: flex;
    height: clamp(185px, 48.535vw, 497px);
    width: 100%;
    object-fit: cover;
  }
}

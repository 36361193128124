.comment {
  display: flex;
  flex-direction: column;
  grid-gap: 6px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
  }

  &__username {
    color: var(--color-pink);
    font-weight: 700;
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
  }

  &__pubtime {
    font-size: 14px;
    line-height: 18px;
    color: var(--color-gray);
  }
}

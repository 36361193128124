.btn {
  width: 100%;
  padding: 10px 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  background-color: var(--color-black);
  border-radius: 50px;
  font-family: var(--font-family-gotham);
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: var(--color-white);
  text-transform: uppercase;
  transition: all 0.3s ease;
}

h1 {
  font-size: clamp(20px, 2.56vw, 32px);
  line-height: clamp(24px, 3.04vw, 38px);
  font-weight: 700;
}

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

.heading {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}



.top-user {
  display: flex;
  align-items: center;
  grid-gap: 12px;

  &__image {
    display: flex;
    width: 56px;
    height: 56px;

    picture,
    img {
      width: 100%;
      border-radius: 15%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;

    a {
      font-size: 20px;
      line-height: 24px;
      color: var(--color-pink);
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.music {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: clamp(16px, 2.344vw, 24px);
  }

  &__file {
    position: relative;
    margin-bottom: clamp(34px, 4.492vw, 46px);

    audio {
      position: relative;
      width: 100%;
    }
  }

  &__player {
    width: 100%;
    margin-bottom: 46px;
    display: flex;
    align-items: center;
    grid-gap: 18px;
  }

  &__player-details {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    span {
      font-size: 14px;
      line-height: 17px;
      color: var(--color-gray-mid);
    }

    input[type="range"] {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      cursor: pointer;
      outline: none;
      overflow: hidden;
    }

    /* Track: webkit browsers */
    input[type="range"]::-webkit-slider-runnable-track {
      height: 4px;
      background: var(--color-grey);
    }

    /* Track: Mozilla Firefox */
    input[type="range"]::-moz-range-track {
      height: 4px;
      background: var(--color-grey);
    }

    /* Thumb: webkit */
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 1px;
      width: 1px;
      background-color: var(--color-black);
      box-shadow: -400px 0 0 400px var(--color-black);
    }


    /* Thumb: Firefox */
    input[type="range"]::-moz-range-thumb {
      height: 1px;
      width: 1px;
      background-color: var(--color-black);
      box-shadow: -400px 0 0 400px var(--color-black);
    }
  }

  &__controls {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    &.pause {
      .pause-btn {
        display: block;
      }

      .play-btn {
        display: none;
      }
    }

    .pause-btn {
      display: none;
    }
  }

  &__banner {
    position: relative;
    margin-bottom: clamp(16px, 4.492vw, 46px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(215px, 28.4vw, 355px);
    height: clamp(215px, 28.4vw, 355px);

    picture,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__banner-upload {
    width: fit-content;

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  &__banner-trigger {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: var(--color-pink);
    cursor: pointer;
  }

  &__bot {
    display: flex;
    align-items: center;
    grid-gap: 18px;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-gray);
  }
}

.recommendation {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  &__list {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;

    a {
      font-size: 16px;
      line-height: 20px;
      color: var(--color-pink);
    }
  }
}

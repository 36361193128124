.advices {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  &__head {
    font-size: clamp(20px, 2.344vw, 24px);
    line-height: clamp(24px, 2.734vw, 28px);
    font-weight: 700;
  }
}

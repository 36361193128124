.users {
  li {
    position: relative;
    counter-increment: list;
    padding: 13px 0 8px;
    border-bottom: 1px solid var(--color-grey);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 1px solid transparent;
    }
  }

  &__wrapp {
    display: flex;
    align-items: center;
    grid-gap: 12px;
  }

  &__image {
    display: flex;
    width: 56px;
    height: 56px;

    picture,
    img {
      width: 100%;
      border-radius: 15%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    grid-gap: 4px;

    p {
      font-size: 14px;
      line-height: 18px;
      color: var(--color-gray-mid);
    }
  }

  &__nickname {
    grid-gap: 5px;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-pink);

    span {
      color: var(--color-black);
    }
  }
}

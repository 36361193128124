.feedback {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 10px;

  @media (max-width: 576px) {
    grid-gap: 8px;
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;

    span {
      color: #CCC;
    }

    @media (max-width: 992px) {
      br {
        display: none;
      }
    }
  }

  &__email {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: var(--color-pink);

    @media (max-width: 576px) {
      margin-bottom: 12px;
    }
  }

  &__copyright {
    font-family: var(--font-family-manrope);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: var(--color-gray);
  }
}

.top-users {
  display: flex;
  flex-direction: column;

  &__item {
    position: relative;
    counter-increment: list;
    padding: 13px 0 8px;
    border-bottom: 1px solid var(--color-grey);

    &::before {
      content: counter(list);
      position: absolute;
      height: 23px;
      top: 5px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      color: var(--color-black);
      padding: 3px 5px;
      border-radius: 50px;
      border: 2px solid var(--color-white);
      background-color: var(--color-yellow);
      z-index: 1;
    }

    &:first-child {
      padding-top: 0;

      &::before {
        top: -5px;
      }
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 1px solid transparent;
    }
  }
}

.add-file {
  &__title {
    margin-bottom: 25px;
  }

  &__rules {
    margin-bottom: 20px;
  }

  &__rules-trigger {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    font-weight: 700;
    cursor: pointer;

    svg {
      stroke: var(--color-pink);
      transition: all 0.4s ease-in-out;
    }

    &._active {
      svg {
        transform: scale(-1);
      }
    }
  }

  &__rules-descr {
    padding-top: 20px;
    max-width: 440px;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-gray-mid);

    span {
      color: var(--color-pink);
    }
  }

  &__rules-alert {
    margin-top: 26px;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    font-size: 14px;
    line-height: 20px;
  }

  &__attention {
    color: var(--color-gray-mid);

    span {
      color: var(--color-black);
    }
  }

  &__types {
    color: var(--color-pink);
  }

  &__limits {
    margin-top: 33px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-gray-mid);

    b {
      display: block;
      margin-bottom: 12px;
      color: var(--color-black);
    }

    span {
      color: var(--color-black);
    }

    p + p {
      margin-top: 8px;
    }
  }

  &__form {
    max-width: 440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }

  &__uploads {
    display: flex;
    align-items: center;
    grid-gap: 12px;

    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__upload {
    width: fit-content;

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      visibility: hidden;
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  &__upload-trigger {
    padding: 10px 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    font-size: 14px;
    line-height: 17px;
    border-radius: 50px;
    border: 2px dashed var(--color-pink);
    cursor: pointer;
  }

  &__upload-box {
    font-size: 14px;
    line-height: 17px;
    color: var(--color-gray);
  }

  &__18plus {
    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      visibility: hidden;

      &:checked ~ span::before {
        border: 2px solid var(--color-pink);
      }

      &:checked ~ span::after {
        opacity: 1;
        visibility: visible;
      }
    }

    span {
      position: relative;
      padding-left: 30px;
      font-size: 14px;
      line-height: 17px;
      color: var(--color-gray-mid);
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 20px;
        height: 20px;
        border: 2px solid #CCC;
        transition: all 0.4s ease;
      }

      &::after {
        content: '';
        position: absolute;
        background-image: var(--checked-icon);
        background-size: cover;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    grid-gap: 7px;
  }

  &__description-heading {
    font-size: 14px;
    line-height: 18px;
    color: var(--color-gray-mid);

    span {
      color: var(--color-gray);
    }
  }

  &__textarea {
    height: 116px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 12px;
    resize: none;

    &::placeholder {
      color: var(--color-gray);
    }
  }

  &__comments {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
  }

  &__comments-heading {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
  }

  &__comments-variants {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }

  &__comments-variant {
    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      visibility: hidden;

      &:checked ~ span {
        color: var(--color-black);

        &::before {
          border: 2px solid var(--color-pink);
        }
      }

      &:checked ~ span::after {
        opacity: 1;
        visibility: visible;
      }
    }

    span {
      position: relative;
      padding-left: 30px;
      font-size: 14px;
      line-height: 17px;
      color: var(--color-gray-mid);
      transition: all 0.4s ease;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 20px;
        height: 20px;
        border: 2px solid #CCC;
        border-radius: 50%;
        transition: all 0.4s ease;
      }

      &::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 4px;
        background-color: var(--color-pink);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease;
      }
    }
  }
}

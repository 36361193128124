.textblock {
  h1 {
    margin-bottom: 20px;
  }

  p {
    font-size: clamp(14px, 1.28vw, 16px);
    line-height: clamp(16px, 1.44vw, 18px);
    color: var(--color-gray-mid);
  }
}

:root {
  --change-icon: url("data:image/svg+xml,%3Csvg width='87' height='53' viewBox='0 0 87 53' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.3758 8.19287L69.9237 26.7408L51.3758 45.2888' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M35.301 45.2888L16.753 26.7409L35.301 8.19293' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  --checked-icon: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 6L9 17L4 12' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  --play-btn: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='30' cy='30' r='30' fill='%23F9EA4A'/%3E%3Cpath d='M43.333 30L23.333 41.547L23.333 18.453L43.333 30Z' fill='black'/%3E%3C/svg%3E%0A");
  --pause-btn: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='18' cy='18' r='18' fill='%23F9EA4A'/%3E%3Cpath d='M15 12L15 24M22 12L22 24' stroke='black' stroke-width='3'/%3E%3C/svg%3E");





}



.edit-file {
  &__form {
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }



  &__18plus {
    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      visibility: hidden;

      &:checked ~ span::before {
        border: 2px solid var(--color-pink);
      }

      &:checked ~ span::after {
        opacity: 1;
        visibility: visible;
      }
    }

    span {
      position: relative;
      padding-left: 30px;
      font-size: 14px;
      line-height: 17px;
      color: var(--color-gray-mid);
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 20px;
        height: 20px;
        border: 2px solid #CCC;
        transition: all 0.4s ease;
      }

      &::after {
        content: '';
        position: absolute;
        background-image: var(--checked-icon);
        background-size: cover;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease;
      }
    }
  }

  &__description,
  &__title {
    display: flex;
    flex-direction: column;
    grid-gap: 7px;
  }

  &__description-heading {
    font-size: 14px;
    line-height: 18px;
    color: var(--color-gray-mid);

    span {
      color: var(--color-gray);
    }
  }

  &__input {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 12px;
    resize: none;

    &::placeholder {
      color: var(--color-gray);
    }

    &:focus {
      border: 1px solid var(--color-black);
    }
  }

  &__textarea {
    height: 116px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 12px;
    resize: none;

    &::placeholder {
      color: var(--color-gray);
    }

    &:focus {
      border: 1px solid var(--color-black);
    }
  }

  &__comments {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
  }

  &__comments-heading {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
  }

  &__comments-variants {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }

  &__comments-variant {
    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      visibility: hidden;

      &:checked ~ span {
        color: var(--color-black);

        &::before {
          border: 2px solid var(--color-pink);
        }
      }

      &:checked ~ span::after {
        opacity: 1;
        visibility: visible;
      }
    }

    span {
      position: relative;
      padding-left: 30px;
      font-size: 14px;
      line-height: 17px;
      color: var(--color-gray-mid);
      transition: all 0.4s ease;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 20px;
        height: 20px;
        border: 2px solid #CCC;
        border-radius: 50%;
        transition: all 0.4s ease;
      }

      &::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 4px;
        background-color: var(--color-pink);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease;
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    grid-gap: 22px;
  }

  &__save {
    width: fit-content;
    padding: 10px 18px;
    text-transform: capitalize;
  }
}

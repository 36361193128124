.breadcrumbs {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  justify-content: flex-start;
  user-select: none;

  &__prev {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-pink);
    white-space: nowrap;

    &::before {
      content: "/";
      position: absolute;
      width: 2px;
      height: 100%;
      right: -6px;
      color: #CCC;
    }
  }

  &__current {
    font-size: 14px;
    line-height: 18px;
    color: var(--color-pink);
    white-space: nowrap;
  }
}

.select {
  width: 100%;
	position: relative;

  svg {
    transition: all 0.3s ease;
  }

  &.is-active {
    .select__body {
      position: absolute;
      display: flex;
      z-index: 10;
    }

    svg {
      transform: scale(-100%);
    }
  }

  &__header {
    height: 100%;
    padding: 0 18px;
    display: flex;
    align-items: center;
    grid-gap: 20px;
    border-radius: 50px;
    border: 1px solid #CCC;
    cursor: pointer;
  }

  &__current {
    flex-grow: 1;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-gray);
  }

  &__body {
    position: absolute;
    width: 100%;
    top: 100%;
    display: none;
    border-radius: 8px;
    box-shadow: 0px 8px 20px 0px rgba(139, 136, 153, 0.25);
    transition: all .3s ease;

  }

  &__variants {
    width: 100%;
    background-color: var(--color-white);
    padding: 10px 18px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;

    li {
      font-size: 14px;
      line-height: 18px;
      color: var(--color-black);
      cursor: pointer;
    }

    span {
      pointer-events: none;
    }
  }
}



